<template>
  <article v-if="dataReview" class="review-card">
    <div class="user-info-container-desktop">
      <p v-if="isShowCasinoName" class="casino-name">
        {{ getCasinoName }}
      </p>
      <div class="user-info-container">
        <img
          :src="getImage(dataReview.avatar, 'avatar', '50')"
          :srcset="`${getImage(dataReview.avatar, 'avatar', '100')} 2x`"
          alt="image"
          class="avatar"
          height="50"
          width="50"
        >
        <div class="user-info">
          <p v-if="dataReview.username" class="name">
            {{ dataReview.username }}
          </p>
          <p v-if="dataReview.createdAt" class="date">
            {{ getFormattedDate }}
          </p>
          <BRRating
            v-if="dataReview.rating || dataReview.rating === 0"
            :value="dataReview.rating"
            class="review-rating"
            color="var(--primary-icon-color)"
            readonly
          />
        </div>
      </div>
      <div class="actions_desktop">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="dataReview.text" class="comment" v-html="$sanitize(dataReview.text)" />
    <p v-else class="comment">
      'No comment'
    </p>
    <div class="actions_mobile">
      <slot name="actions" />
    </div>
  </article>
</template>

<script>
import BRRating from '@/components/BRComponents/BRRating.vue'
import { formatDate } from '@/services/date'
import images from '@/mixins/image'

export default {
  name: 'BRReviewCard',
  components: {
    BRRating
  },
  mixins: [images],
  props: {
    dataReview: {
      type: Object,
      required: true
    },
    isShowCasinoName: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    getFormattedDate() {
      return this.formatDate(this.dataReview.createdAt, 'time')
    },
    getCasinoName() {
      return this.$contentful.reviews.find(casino => casino.id === this.dataReview.itemId)?.name
    }
  },
  methods: {
    formatDate
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.user-info-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 16px;

  .avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .user-info {
    .name {
      margin-bottom: 2px;
      font-weight: var(--font-weight-bold);
      font-size: var(--font-base);
      color: var(--text-color);
    }

    .date {
      font-size: var(--font-xs);
      color: var(--secondary-text-color);
    }
  }
}

.review-card {
  border-radius: var(--border-radius-default);
  padding: 18px;
  background-color: var(--bg-color);

  .casino-name {
    margin-bottom: 20px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-base);
    color: var(--text-color);
  }

  @include breakpoint(medium) {
    display: flex;
    align-items: stretch;
    padding: 16px;
    gap: 22px;
  }
}

.review-card .comment {
  margin-bottom: 12px;
  border-radius: var(--border-radius-default);
  padding: 12px 14px;
  width: 100%;
  font-size: var(--font-base);
  color: var(--secondary-text-color);
  background-color: var(--content-bg-color);

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.actions_mobile {
  @include breakpoint(medium) {
    display: none;
  }
}

.actions_desktop {
  @include breakpoint-reverse(small) {
    display: none;
  }
}

.user-info-container-desktop {
  @include breakpoint(medium) {
    width: 100%;
    max-width: 186px;
  }
}

</style>
