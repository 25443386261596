<template>
  <BRReviewCard :data-review="dataReview" :is-show-casino-name="isShowCasinoName">
    <template #actions>
      <div :class="['actions', {'actions_useful-btn' : !dataReview.canEdit}]">
        <template v-if="dataReview.canEdit">
          <BRButton
            :color="$vuetify.breakpoint.xs ? 'var(--quaternary-bg-color)' : 'transparent'"
            class="action"
            type="button"
            width="100%"
            @click="editComment(dataReview)"
          >
            <BRIcon class="icon" icon="edit-rounded" />
            <span class="btn-text">Edit</span>
          </BRButton>
          <BRButton
            :color="$vuetify.breakpoint.xs ? 'var(--quaternary-bg-color)' : 'transparent'"
            class="action"
            type="button"
            width="100%"
            @click="deleteComment"
          >
            <BRIcon class="icon" icon="delete" />
            <span class="btn-text">Delete</span>
          </BRButton>
        </template>
        <template v-else>
          <!--          TODO: for admin?-->
          <!--          <BRButton-->
          <!--            v-if="!reply"-->
          <!--            :color="$vuetify.breakpoint.xs ? 'var(&#45;&#45;quaternary-bg-color)' : 'transparent'"-->
          <!--            class="action"-->
          <!--            type="button"-->
          <!--            width="100%"-->
          <!--            @click="addReply"-->
          <!--          >-->
          <!--            <BRIcon class="icon" icon="reply" />-->
          <!--            <span class="btn-text">Reply</span>-->
          <!--          </BRButton>-->
          <BRButton
            :class="['action', {'is-liked' : dataReview.isLiked }]"
            :height="$vuetify.breakpoint.xs ? '35' : '40'"
            color="var(--button-text-color)"
            type="button"
            width="100%"
            :loading="loading"
            @click="setCommentUseful"
          >
            <v-icon class="thumb-up">
              mdi-thumb-up
            </v-icon>
            <span class="btn-text">Useful!</span>
          </BRButton>
        </template>
      </div>
    </template>
  </BRReviewCard>
</template>

<script>
import BRReviewCard from '@/components/BRComponents/BRReviewCard.vue'
import api from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'BRActionsComments',
  components: { BRReviewCard },
  props: {
    casino: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    dataReview: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    reply: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowCasinoName: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLiked: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  },
  methods: {
    editComment(comment) {
      this.$emit('editComment', comment)
    },
    async deleteComment() {
      const isConfirmed = confirm('Are you sure you want to delete this comment?')

      if (!isConfirmed) {
        return
      }

      const {
        error
      } = await api.comments.deleteUserComment(this.dataReview.itemId || this.casino.id, this.dataReview.id)
      if (!error) {
        this.$emit('updateComments')
      }
    },
    async setCommentUseful() {
      if (!this.getUserDB) {
        this.$router.push({
          name: 'Login',
          query: { redirect: this.$route.path }
        })

        return
      }

      this.loading = true

      this.isLiked = await api.comments.toggleCommentLike(this.casino.id, this.dataReview.id)
      this.$emit('updateComments')
      this.loading = false
    },
    // TODO: its for admins
    addReply() {
      if (!this.getUserDB) {
        this.$router.push({
          name: 'Login',
          query: { redirect: this.$route.path }
        })
      }

      this.isOpenReplies = true
      this.$emit('addReply', this.comment)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.actions {
  position: relative;
  display: flex;
  margin-top: 12px;
  margin-bottom: 0;
  padding: 0;
  gap: 10px;

  .action {
    display: flex;
    flex-shrink: 1;
    align-items: center;
    margin-right: 0;
    border-radius: 8px;
    padding: 12px 0;
    background-color: var(--quaternary-bg-color);

    @include breakpoint(medium) {
      background-color: transparent;
    }
  }

  .btn-text {
    font-weight: var(--font-weight-normal);
  }

  .icon {
    width: 10px;
    height: 10px;

    @include breakpoint(medium) {
      width: 14px;
      height: 14px;
    }
  }

  span {
    @include subtitle;
  }
}

.actions::before {
  content: "";
  position: absolute;
  top: 0;
  display: none;
  width: 100%;
  height: 1px;
  background-color: var(--secondary-border-color);
  opacity: 0.2;

  @include breakpoint(medium) {
    display: block;
  }
}

.actions_useful-btn::before {
  display: none;
}

.action {
  border-radius: var(--border-radius-default);
  transition: background-color 0.3s;

  ::v-deep .button-text {
    display: flex;
    align-items: center;
    color: var(--secondary-text-color);
    gap: 8px;
  }

  .thumb-up {
    font-size: var(--font-base);
    color: var(--secondary-text-color);
  }
}

.action.is-liked {
  ::v-deep .btn-text {
    color: var(--primary-text-color);
  }

  .thumb-up {
    color: var(--primary-text-color);
  }
}

</style>
