<template>
  <BRModal :is-dialog-open="isOpen" @close="closeDialog">
    <template #title>
      Your comment
    </template>
    <template #description>
      <v-form>
        <div v-if="user" class="main-info">
          <img
            :src="getContentfulImage()"
            alt="user avatar"
            class="mr-4 avatar"
            height="40"
            width="40"
          >
          <span class="user-name">{{ user.username }}</span>
          <BRRating
            :size="18"
            :value="ratingValue"
            class="rating"
            color="var(--primary-icon-color)"
            @changeRatingValue="changeRatingValue"
          />
        </div>
        <VueEditor
          v-model="commentText"
          :editor-toolbar="toolbarConfig"
          class="editor"
        />
        <div class="actions">
          <BRButton
            :height="$vuetify.breakpoint.xs ? '40' : '45'"
            :width="$vuetify.breakpoint.xs ? '100%' : 'auto'"
            class="close-comment-btn action"
            color="var(--secondary-bg-color)"
            outlined
            type="button"
            @click="closeDialog"
          >
            close
          </BRButton>
          <BRButton
            :height="$vuetify.breakpoint.xs ? '40' : '45'"
            :width="$vuetify.breakpoint.xs ? '100%' : 'auto'"
            class="send-comment-btn action"
            dark
            @click="updateComment"
          >
            update comment
          </BRButton>
        </div>
      </v-form>
    </template>
  </BRModal>
</template>

<script>
import api from '@/api'
import images from '@/mixins/image'
import BRRating from '@/components/BRComponents/BRRating.vue'
import BRModal from '@/components/BRComponents/BRModal.vue'
import { mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'
import vue2EditorConfig from '@/configs/vue2EditorConfig'

export default {
  name: 'UserCommentForm',
  components: {
    BRModal,
    BRRating,
    VueEditor
  },
  mixins: [images],
  props: {
    user: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    casinoId: {
      type: String,
      required: false,
      default: ''
    },
    casinoType: {
      type: String,
      required: false,
      default: 'casino'
    },
    comment: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    replyId: {
      type: Number,
      required: false,
      default: 0
    },
    isShowCommentDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      commentText: '',
      isFormValid: false,
      ratingValue: 0,
      toolbarConfig: vue2EditorConfig
    }
  },
  computed: {
    ...mapGetters(['getUserDB'])
  },
  watch: {
    isShowCommentDialog(value) {
      this.isOpen = value
    },
    comment(value) {
      this.commentText = value.text
      this.ratingValue = value.rating
    }
  },
  methods: {
    changeRatingValue(value) {
      this.ratingValue = value
    },
    closeDialog() {
      this.$emit('update:isShowCommentDialog', false)
    },
    async updateComment() {
      const { error } = await api.comments.updateUserComment(this.comment.itemId || this.casinoId, {
        parentId: 0,
        text: this.$sanitize(this.commentText),
        rating: this.ratingValue,
        id: this.comment.id
      })

      if (!error) {
        this.closeDialog()
        this.$emit('updateComments')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user-name {
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 36px;
  gap: 10px;

  .action ::v-deep .button-text {
    text-transform: uppercase;
  }
}

.avatar {
  border-radius: 50%;
  min-width: 40px;
  object-fit: cover;
}

.main-info {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .rating {
    margin-left: auto;
  }
}

.close-comment-btn {
  flex-shrink: 1;
}

.send-comment-btn {
  flex-shrink: 1;

  &.v-btn--disabled.v-btn--has-bg {
    color: var(--button-text-color) !important;
    background-color: var(--primary-button-bg-color) !important;
    opacity: 0.5;
  }
}

</style>
<style lang="scss">
@import "@/assets/style/mixins.scss";

.editor {
  @include vue-editor;
}
</style>
