<template>
  <v-rating
    v-model="ratingValue"
    :color="color"
    :hover="hover"
    :readonly="readonly"
    :size="size"
    background-color="var(--empty-rating-color)"
    class="blockreviews-rating"
    empty-icon="$ratingFull"
    half-increments
    length="5"
    @input="changeRatingValue"
  />
</template>

<script>
export default {
  name: 'BRRating',
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    },
    size: {
      type: Number,
      required: false,
      default: 16
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    hover: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      required: false,
      default: 'var(--default-rating-color)'
    }
  },
  data() {
    return {
      ratingValue: this.value
    }
  },
  watch: {
    value(newValue) {
      this.ratingValue = newValue
    }
  },
  methods: {
    changeRatingValue() {
      this.$emit('changeRatingValue', this.ratingValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.blockreviews-rating::v-deep.v-rating .v-icon {
  margin-right: 1px;
  padding: 0;

  &:last-child {
    margin-right: 0;
  }
}
</style>
