export const timeLeft = (dateStart, dateEnd) => {
  const periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
  }
  const diff = Math.round(new Date(dateEnd)) - Math.round(new Date(dateStart))

  if (diff > periods.month) {
    const dateCount = Math.floor(diff / periods.month)
    return dateCount > 1 ? `${dateCount} months` : `${dateCount} month`
  }
  if (diff > periods.week) {
    const dateCount = Math.floor(diff / periods.week)
    return dateCount > 1 ? `${dateCount} weeks` : `${dateCount} week`
  }
  if (diff > periods.day) {
    const dateCount = Math.floor(diff / periods.day)
    return dateCount > 1 ? `${dateCount} days` : `${dateCount} day`
  }
  if (diff > periods.hour) {
    const dateCount = Math.floor(diff / periods.hour)
    return dateCount > 1 ? `${dateCount} hours` : `${dateCount} hour`
  }
  if (diff > periods.minute) {
    const dateCount = Math.floor(diff / periods.minute)
    return dateCount > 1 ? `${dateCount} minutes` : `${dateCount} minute`
  }
  if (diff > periods.second) {
    const dateCount = Math.floor(diff / periods.second)
    return dateCount > 1 ? `${dateCount} seconds` : `${dateCount} second`
  }
  return null
}

export const formatDate = (date, format = 'date') => {
  const parsedDate = Date.parse(date)
  if (format === 'time') {
    const formattedDateWithTime = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    }).format(parsedDate)
    // returns '10/06/2021 10:25 AM' with local timezone
    return formattedDateWithTime
  } else {
    const formattedDate = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'numeric',
      day: '2-digit'
    }).format(parsedDate)
    // returns '10/06/2021' with local timezone
    return formattedDate
  }
}
