<template>
  <div class="tabs">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      :class="['tab-item', { 'active' : tab.name === activeTabName}]"
      @click="changeActiveTab(tab)"
    >
      <BRIcon
        v-if="icon(tab)"
        :icon="icon(tab)"
        class="icon"
      />
      {{ tab.title }}
    </div>
  </div>
</template>

<script>
import BRIcon from '@/components/BRComponents/BRIcon.vue'

export default {
  name: 'BRTabs',
  components: {
    BRIcon
  },
  props: {
    tabs: {
      type: Array,
      required: true,
      default: () => []
    },
    tabWidth: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      activeTabName: this.tabs[0].name
    }
  },
  computed: {
    isExistComponent() {
      return !!this.tabs.find(tab => tab.name === this.$route.query.tab)
    }
  },
  mounted() {
    if (this.isExistComponent) {
      this.activeTabName = this.$route.query.tab
    }
    this.$emit('changeActiveTab', this.activeTabName)
  },
  methods: {
    changeActiveTab(tab) {
      this.activeTabName = tab.name

      this.$emit('changeActiveTab', tab.name)
      if (this.$route.query.tab === tab.name) return

      this.$router.push({
        query: { tab: tab.name }
      })
    },
    icon(tab) {
      return tab.icon ? tab.icon : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.tabs {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-default);
  padding: 9px;
  width: 100%;
  height: 50px;
  max-width: calc(100vw - 40px);
  box-shadow: var(--box-shadow-light);
  font-size: var(--font-base);
  background-color: var(--content-bg-color);
  gap: 2px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint-reverse(small) {
    overflow: auto hidden;
    padding: 6px;
    height: 44px;
    max-width: calc(100vw - 40px);
    white-space: nowrap;
  }
}

.tab-item {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
  padding: 8px 20px;
  min-width: 140px;
  font-weight: var(--font-weight-normal);
  text-transform: capitalize;
  color: var(--text-color);
  cursor: pointer;
  user-select: none;

  .icon {
    margin-right: 12px;

    &::v-deep .blockreviews-icon {
      color: var(--text-color);
    }

    @include breakpoint-reverse(small) {
      margin-right: 5px;
      width: 12px;
      height: 12px;
    }
  }

  @include breakpoint-reverse(small) {
    padding: 10px 14px;
    min-width: fit-content;
  }
}

.tab-item:hover,
.active {
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);

  .icon::v-deep .blockreviews-icon {
    color: var(--primary-icon-color);
  }
}

.active {
  font-weight: var(--font-weight-bold);
}
</style>
